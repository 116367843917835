<template>
  <div>
    <div class="pageInfo publicPage">
      <div class="content">
        <div class="title">{{ artInfo.title }}</div>
        <div class="time">{{ timefmt(artInfo.write_time) }}</div>
        <div class="time">{{ artInfo.writer }}</div>
        <img :src="artInfo.img" alt="" />
        <div class="text">
          {{ artInfo.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_article } from "../request/apis";
import dayjs from "dayjs";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      id: "",
      artInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    let query = this.$route.query;
    this.id = query.id;
    this.getList(this.id);
  },
  methods: {
    //时间格式化
    timefmt(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    //获取文章
    getList(id) {
      get_article(id).then((res) => {
        if (res) {
          this.artInfo = res;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pageInfo {
  width: 100vw;
  .content {
    width: 1100px;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
    .time,
    .title {
      margin: 10px 0px;
    }
    .time {
      color: #cca332;
      font-weight: bold;
    }
    .title {
      font-size: 18px;
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .pageInfo {
      .content {
        width: 100%;
      }
    }
  }
}
</style>
